import { Button, Loader, Radio, Typography, DatePickerInput, useSnackbar } from '@htaic/cue'
import { useState, useCallback, memo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import {
  useGetAvailableLicenses,
  useGetLicenseStatus,
  useUpdateToClaimLicense,
} from '@training/apis/licenses/requests'
import {
  LicenseStatus,
  type ClaimLicense,
  type AvailableLicensesResponse,
} from '@training/apis/types'
import { getRedirectUrl } from '@training/utils/getRedirectUrl'
import { useMinimeState } from '@training/store/useMinimeState'

interface UnassignedLicensesSelectorProps {
  licenses: AvailableLicensesResponse[]
  onChange: (licenseKey: string) => void
}

const UnassignedLicensesSelector = memo((props: UnassignedLicensesSelectorProps) => {
  const { licenses, onChange } = props

  return (
    <Radio
      items={licenses.map((lic) => ({
        label: lic.licenseKey,
        value: lic.licenseKey,
        key: lic.licenseKey,
      }))}
      data-testid='license-selector'
      onChange={(event) => onChange(event.target.value)}
      defaultValue={licenses?.[0].licenseKey}
    />
  )
})

const License = () => {
  const orgIdState = useMinimeState((state) => state.orgId)

  const cloudPlatformLoginUrl = getRedirectUrl()
  const { data: licenses = [], isPending, refetch } = useGetAvailableLicenses()

  const { isPending: isPendingClaim, mutateAsync: mutateAsyncClaim } = useUpdateToClaimLicense()
  const { refetch: refetchStatus } = useGetLicenseStatus(orgIdState, { enabled: false })

  const navigate = useNavigate()
  const showSnackBar = useSnackbar()

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [selectedLicense, setSelectedLicense] = useState('')

  const onChange = (newActivateDataValue: Dayjs | null, selectedLicenseKey: string) => {
    setStartDate(newActivateDataValue)
    setSelectedLicense(selectedLicenseKey)
  }

  const handleGoToHome = async () => {
    try {
      await refetchStatus()
      navigate('/')
    } catch (error) {
      console.log('Error trying to load licenses.', error)
    }
  }

  const hasActiveLicenses = licenses.some(
    (license) => license.licenseStatus === LicenseStatus.ACTIVE
  )

  const hasPendingActive = licenses.some(
    (license) => license.licenseStatus === LicenseStatus.ASSIGNED_ACTIVATION_PENDING
  )
  const hasUnassignedLicenses = licenses.some(
    (license) => license.licenseStatus === LicenseStatus.UNASSIGNED
  )
  const renderNotFoundValidLicenses = () => {
    return (
      <>
        <Typography variant='body2' className='text-semantic-secondary'>
          Coming soon.
        </Typography>
        <Typography variant='body2' className='text-semantic-secondary'>
          Contact your System Integrator (SI) to get a demo.
        </Typography>
        <Button className='mt-8' data-testid='license-back-button'>
          <Link to={cloudPlatformLoginUrl} className='no-underline text-primary hover:text-white'>
            Return to Cloud Portal
          </Link>
        </Button>
      </>
    )
  }

  const createClaimLicense = (licenseKey: string, activeDate: number): ClaimLicense => {
    return {
      licenses: [
        {
          licenseKey,
          activeDate,
        },
      ],
    }
  }

  const activateLicense = useCallback(
    async (claimLicense: ClaimLicense) => {
      try {
        const claimLicenseResponse = await mutateAsyncClaim(claimLicense)

        if (claimLicenseResponse?.meta.code !== 200) {
          showSnackBar({
            message:
              claimLicenseResponse?.meta.userMsg ===
              "The activeDate must greater than or equal to today's date. Please try again."
                ? 'Start date cannot be in the past. Please try again.'
                : claimLicenseResponse?.meta.userMsg,
            status: 'error',
          })
          return
        }

        if (claimLicenseResponse?.meta.code === 200) {
          showSnackBar({
            message: `License "${claimLicenseResponse?.data[0].licenseKey}" is now active.`,
            status: 'success',
          })
          refetch()
        }
      } catch (claimLicenseError: any) {
        showSnackBar({
          message:
            claimLicenseError.message ===
            "The activeDate must greater than or equal to today's date. Please try again."
              ? 'Start date cannot be in the past. Please try again.'
              : claimLicenseError.message,
          status: 'error',
        })
      }
    },
    [mutateAsyncClaim, refetch]
  )

  const handleActivateLicense = () => {
    if (startDate === null) return

    const unixTimestamp = startDate?.add(dayjs().diff(startDate)).valueOf()

    const claimLicensePayload = createClaimLicense(
      selectedLicense || licenses[0]?.licenseKey,
      unixTimestamp
    )

    activateLicense(claimLicensePayload)
  }

  const handleSelectLicense = useCallback((value: string) => {
    setSelectedLicense(value)
  }, [])

  const renderSelectLicense = () => {
    const unassignedLicenses = licenses.filter(
      (license) => license.licenseStatus === LicenseStatus.UNASSIGNED
    )
    const licenseKey =
      unassignedLicenses.length === 1 ? unassignedLicenses[0].licenseKey : selectedLicense
    return (
      <>
        {unassignedLicenses.length === 1 ? (
          <div className='flex justify-center flex-col mb-7'>
            <span className='flex justify-center mb-7'>
              <Typography variant='body2' className='text-semantic-secondary self-center'>
                Please confirm and activate your license key.
              </Typography>
            </span>
            <Typography variant='body1' className='text-semantic-primary mb-4 self-center'>
              {unassignedLicenses[0].licenseKey}
            </Typography>
          </div>
        ) : (
          <div className='flex justify-start flex-col mb-7'>
            <Typography variant='body2' className='text-semantic-secondary mb-7'>
              Select the license key you wish to activate.
            </Typography>
            <UnassignedLicensesSelector
              licenses={unassignedLicenses}
              onChange={handleSelectLicense}
            />
          </div>
        )}
        <div className='mb-7'>
          <Typography variant='body2' className='text-semantic-secondary font-semibold'>
            Start date
          </Typography>
          <DatePickerInput
            value={startDate}
            onChange={(e) => onChange(e as Dayjs, licenseKey)}
            minDate={dayjs()}
          />
        </div>
        <Button
          disabled={startDate === null}
          onClick={handleActivateLicense}
          data-testid='activate-license-button'
        >
          Activate
        </Button>
      </>
    )
  }

  const renderCurrentActiveLicense = () => {
    const activeLicense = licenses.find((license) => license.licenseStatus === LicenseStatus.ACTIVE)
    const formattedDate = dayjs(activeLicense?.expiryDate).format('MMM D, YYYY')
    return (
      <>
        <span className='mb-7'>
          <Typography variant='body2' className='text-semantic-secondary'>
            The following license has been activated and will expire on <b>{formattedDate}</b>.
          </Typography>
        </span>

        <span className='mb-7'>
          <Typography variant='body2' className='mb-7'>
            {activeLicense?.licenseKey}
          </Typography>
        </span>

        <Button onClick={handleGoToHome} data-testid='license-back-button'>
          Enter FLEX AI
        </Button>
      </>
    )
  }

  const renderPendingActiveLicense = () => {
    const pendingActiveLicense = licenses.find(
      (license) => license.licenseStatus === LicenseStatus.ASSIGNED_ACTIVATION_PENDING
    )
    const formattedDate = dayjs(pendingActiveLicense?.activeDate).format('MMM D, YYYY')
    return (
      <>
        <Typography variant='body2' className='text-semantic-secondary'>
          The following license is set to go live on <b>{formattedDate}</b>.
        </Typography>

        <Typography variant='body2' className='mb-6 text-semantic-secondary'>
          Please log back in when the license is active.
        </Typography>

        <Typography variant='body2' className='mb-6'>
          {pendingActiveLicense?.licenseKey}
        </Typography>

        <Button
          onClick={handleGoToHome}
          data-testid='license-back-button'
          className='[&>*]:hover:text-white'
        >
          <Link to={cloudPlatformLoginUrl} className='no-underline text-primary hover:text-white'>
            Back to Cloud Portal
          </Link>
        </Button>
      </>
    )
  }

  return (
    <div className='flex flex-col items-center mt-[140px]'>
      <span className='mb-2'>
        <Typography tag='h1' variant='heading2'>
          Welcome to FLEX AI
        </Typography>
      </span>

      {isPending || isPendingClaim ? (
        <div
          className='flex items-center justify-center h-full grow mt-10'
          aria-live='polite'
          aria-label='Loading Licenses'
        >
          <Loader
            size='xxlarge'
            iconName='LoadingThin'
            className='text-primary dark:text-neutral-grey-0'
            label='Loading licenses...'
          />
        </div>
      ) : (
        <>
          {hasActiveLicenses ? renderCurrentActiveLicense() : null}
          {!hasActiveLicenses && hasPendingActive ? renderPendingActiveLicense() : null}
          {!hasActiveLicenses && !hasPendingActive && hasUnassignedLicenses
            ? renderSelectLicense()
            : null}
          {!hasActiveLicenses && !hasPendingActive && !hasUnassignedLicenses
            ? renderNotFoundValidLicenses()
            : null}
        </>
      )}
    </div>
  )
}

export default License
