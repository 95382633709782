import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query'
import axios from 'axios'
import { CreateProject, Project } from '@training/types'
import { BuildInfoResponse, TrainingAction, ValidateAction } from '@training/apis/types'
import { endpoints } from '@training/constants'
import { queryClient } from '@training/apis/query-client'

export const projectsApiUrl = endpoints.VITE_PROJECTS_API ?? 'http://localhost:3014'

export function useCreateProject() {
  const getData = async (payload: CreateProject) => {
    const { data } = await axios.post(`${projectsApiUrl}/v1`, payload)
    return data
  }
  return useMutation({
    mutationKey: ['createProject'],
    mutationFn: (payload: CreateProject) => getData(payload),
    onSuccess: async (projectInserted) => {
      await queryClient.cancelQueries({ queryKey: ['getProjects'] })
      const previousProjects = queryClient.getQueryData<Project[]>(['getProjects'])

      queryClient.setQueryData<Project[]>(['getProjects'], (oldProjects) => {
        if (!oldProjects) return oldProjects
        // Temporary fix of response data
        const projectInsertedVersion = [
          {
            ...projectInserted.version,
            objClasses: [
              {
                ...projectInserted.annotObjClass,
                objectClass: { name: projectInserted.annotObjClass.name },
              },
            ],
          },
        ]
        return [...oldProjects, { ...projectInserted, version: projectInsertedVersion }]
      })

      return { previousProjects }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['getProjects'] })
    },
  })
}

export const getProject = async (id: string) => {
  const { data } = await axios.get<Project>(`${projectsApiUrl}/v1/${id}`)
  return data
}

export function useGetProject(id: string) {
  const getData = async () => {
    const { data } = await axios.get<Project>(`${projectsApiUrl}/v1/${id}`)
    return data
  }
  return useQuery({
    queryKey: ['getProject', id],
    queryFn: () => getData(),
  })
}

export function useUpdateProject() {
  const getData = async (payload: Partial<Project>) => {
    const { data } = await axios.patch(`${projectsApiUrl}/v1/${payload.id}`, payload)
    return data
  }
  return useMutation({
    mutationKey: ['updateProject'],
    mutationFn: (payload: Partial<Project>) => getData(payload),
  })
}

export function useDeleteProject() {
  const deleteProject = async (params: { id: string }) => {
    const { data } = await axios.delete(`${projectsApiUrl}/v2/${params.id}`)
    return data
  }
  return useMutation({
    mutationKey: ['deleteProject'],
    mutationFn: (params: { id: string }) => deleteProject(params),
    onMutate: async (projectToDelete) => {
      await queryClient.cancelQueries({ queryKey: ['getProjects'] })
      const previousProjects = queryClient.getQueryData<Project[]>(['getProjects'])

      queryClient.setQueryData<Project[]>(['getProjects'], (oldProjects) => {
        if (!oldProjects) return oldProjects

        return oldProjects.filter((p: Project) => p.id !== projectToDelete.id)
      })

      return { previousProjects }
    },
    // mutation failed? rollback
    onError: (_err, _projectToDelete, context) => {
      queryClient.setQueryData(['getProjects'], context?.previousProjects)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['getProjects'] })
    },
  })
}

export function useGetProjects(organizationId: string, options: { enabled?: boolean } = {}) {
  const getData = async () => {
    const { data } = await axios.get<Project[]>(`${projectsApiUrl}/v1/`, {
      params: { organizationId },
    })
    return data
  }
  return useQuery({
    queryKey: ['getProjects'],
    queryFn: () => getData(),
    enabled: !!organizationId && options.enabled,
    placeholderData: keepPreviousData,
    gcTime: 1000 * 5,
  })
}

export function useProjectTraining(projectVersionId: string) {
  const getData = async (payload: Partial<TrainingAction>) => {
    const { data } = await axios.post(
      `${projectsApiUrl}/v1/versions/${projectVersionId}/train`,
      payload
    )
    return data
  }
  return useMutation({
    mutationKey: ['updateProject', projectVersionId],
    mutationFn: (payload: Partial<TrainingAction>) => getData(payload),
  })
}

/**
 * A hook to perform validation on a project version.
 *
 * @param {string} projectVersionId - The project version id of the project.
 * @returns {MutationFunction} The mutation function to initiate the validation.
 */
export function useStartProjectValidation(projectVersionId?: string) {
  const getData = async (payload: Partial<ValidateAction>) => {
    try {
      const response = await axios.post(
        `${projectsApiUrl}/v1/versions/${projectVersionId}/validate`,
        payload
      )
      const { data } = response
      return data
    } catch (error: any) {
      throw new Error(`Validation request failed: ${error.response.data.message}`)
    }
  }

  return useMutation({
    mutationKey: ['updateProject', projectVersionId],
    mutationFn: (payload: Partial<ValidateAction>) => getData(payload),
  })
}

/**
 * A hook to perform recomile on a project version.
 *
 * @param {string} projectId - The ID of the project.
 * @returns {MutationFunction} The mutation function to initiate the recompile.
 */
export function useStartProjectRecompile(projectId: string) {
  const getData = async (payload: void) => {
    try {
      const response = await axios.post(`${projectsApiUrl}/v1/${projectId}/compile`, payload)
      const { data } = response
      return data
    } catch (error: any) {
      throw new Error(`Recompile request failed: ${error.response.data.message}`)
    }
  }
  return useMutation({
    mutationKey: ['recompileProject', projectId],
    mutationFn: (payload) => getData(payload),
  })
}

export function downloadProjectModel(projectVersionId: string) {
  return axios.request({
    url: `${projectsApiUrl}/v1/versions/${projectVersionId}/download`,
    method: 'GET',
    responseType: 'blob',
  })
}

export function useGetVersion(orgId: string) {
  const getData = async () => {
    const { data } = await axios.get<BuildInfoResponse>(`${projectsApiUrl}/build-info/v1`)
    return data
  }
  return useQuery({
    queryKey: ['build-info', orgId],
    queryFn: () => getData(),
    staleTime: 1000 * 60 * 1,
    gcTime: 1000 * 60 * 5,
    enabled: !!orgId,
  })
}
