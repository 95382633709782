import { SidebarProvider, SnackbarProvider, ThemeProvider } from '@htaic/cue'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from '@training/apis/query-client'
import { ModalProvider } from '@training/providers/ModalProvider'
import { useMinimeState } from '@training/store/useMinimeState'
import { AxiosConfig } from '@training/apis/axios-config'
import { PopperProvider } from './PopperProvider'

const AxiosProvider = () => {
  const orgId = useMinimeState((state) => state.orgId)

  return orgId ? <AxiosConfig orgId={orgId} /> : null
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <CssBaseline />
          <SnackbarProvider>
            <SidebarProvider defaultOpen>
              <ModalProvider />
              <PopperProvider />
              <AxiosProvider />
              {children}
            </SidebarProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  )
}
