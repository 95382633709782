import type { QueryClient } from '@tanstack/react-query'
import { redirect } from 'react-router-dom'

import { getLicenseStatus } from '@training/apis/licenses/requests'
import type { LicensesStatusResponse } from '@training/apis/types'
import { useMinimeState } from '@training/store/useMinimeState'
import { AxiosError } from 'axios'
import { formatRedirectUrl, getRedirectUrl } from '@training/utils/getRedirectUrl'
import { envVars } from '@training/constants'

/**
 * A React Router loader function that checks the license status for the current organization.
 *
 * @param queryClient - The React Query client instance.
 * @returns A promise that resolves to either null (if the license is valid)
 * or a Redirect object (if the user needs to be redirected).
 */
export const licenseLoader = (queryClient: QueryClient) => async () => {
  const orgId = useMinimeState.getState().orgId

  try {
    const licenseStatus =
      queryClient.getQueryData<LicensesStatusResponse>(['licenseStatus', orgId]) ??
      (await queryClient.fetchQuery<LicensesStatusResponse>({
        queryKey: ['licenseStatus', orgId],
        queryFn: async () => getLicenseStatus(orgId),
      }))

    if (licenseStatus !== 'OK') {
      return redirect('/license')
    }
  } catch (error) {
    console.error('License error: ', error)

    const isRedirectToLicense = error instanceof AxiosError && error.response?.status === 401

    if (isRedirectToLicense) {
      return redirect('/license')
    }

    if (envVars.DEV_DISABLE_REDIRECTS) {
      return null
    }

    // requests dont follow 302 redirects, so we need to manually redirect
    return redirect(formatRedirectUrl(getRedirectUrl(), orgId))
  }

  return null
}
